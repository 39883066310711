import Progress from '@components/common/progress';
import { useBetterMediaQuery } from '@hooks/use-ssr-media-query';
import { Favourite } from '@interfaces/events';
import { deleteFollowEvent } from '@requests/get-pages-content/events';
import { AlertToast, AlertTriangle, Star01, Typography } from '@v2/ui';
import { Spinner } from '@v2/ui/Icon/Spinner/Spinner';
import { themeConfig } from '@v2/utils';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { Placeholder } from './EventsTeamsList';

export function TeamsSection({ list }: { list: Favourite[] | undefined }) {
  const isListEmpty = list?.length === 0;

  if (isListEmpty) return <Placeholder type="teams" />;

  return (
    <div className="grid grid-cols-3 gap-1 md:gap-6">
      {list?.length &&
        list.map((team) => <TeamItem key={team.id} {...team} list={list} />)}
    </div>
  );
}

export function TeamItem({
  name,
  id,
  list: follow,
}: Favourite & { list: Favourite[] }) {
  const [isloading, setLoading] = useState(false);

  const router = useRouter();

  const { update, data } = useSession();
  // @ts-ignore
  const token = data?.user.token.accessToken;
  const isTablet = useBetterMediaQuery('(min-width: 768px)');

  const handleUnfollowTeam = async (favoriteId: number | undefined) => {
    if (!favoriteId) {
      return;
    }

    setLoading(true);

    const unfollow = await deleteFollowEvent(favoriteId, 'performer', token);

    const removedFollowList = follow?.filter((fav) => fav.id !== favoriteId);

    if (!unfollow.error) {
      await update({
        follow: removedFollowList,
      });

      router.refresh();
      setLoading(false);
    } else {
      setLoading(false);
      toast.custom(
        (t) => (
          <AlertToast
            {...t}
            title="Oops, something went wrong"
            content={'Please try again in a minute.'}
            icon={<AlertTriangle />}
          />
        ),
        { position: 'top-right' }
      );
    }
  };

  if (isloading) {
    return (
      <div className="flex h-[90px] w-full items-center justify-center rounded-lg border border-gray-200">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="min-h-[60px] w-full rounded-lg border border-gray-200 p-3 md:p-5">
      <Typography
        size={isTablet ? 'tmd' : 'txs'}
        variant="h3"
        className="inline-flex font-semibold"
      >
        {name}
        <button
          onClick={() => handleUnfollowTeam(id)}
          className="ml-1.5 flex-shrink-0"
        >
          <Star01
            fill={themeConfig.theme.colors.gray[800]}
            className="ml-1.5 w-4 md:block md:w-5"
          />
        </button>
      </Typography>

      <Progress loading={isloading} />
    </div>
  );
}
