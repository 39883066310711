export interface PropsWithChildren {
  children: React.ReactNode;
}

export type Event = {
  id: any;
  date: string;
  time: string;
  title: string;
  venue: string;
  city: string;
};
export interface Traveler {
  id: number;
  userId: number;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  dateOfBirth: string;
  gender: string;
  countryCode: string;
  phoneNumber: string;
  createdAt: string;
  updatedAt: string;
}

export interface EventResponse {
  category: {
    id: string;
    name: string;
    slug: string;
  };
  id: number;
  name: string;
  venue: {
    id: number;
    slug: string;
    name: string;
    image: string;
    location: string;
    address: {
      location: string;
    };
  };
  occurs_at_local: string;
  occurs_at: string;
  performances: {
    performer: {
      id: number;
      name: string;
    };
  }[];
}

export interface PerformerResponse {
  id: number;
  name: string;
  popularity_score: string;
  url: string;
  slug_url: string;
  slug: string;
  venue: {
    id: number;
    slug: string;
    name: string;
  };
  category: {
    id: string;
    name: string;
    slug: string;
  };
  numberOfEvents: number;
}

export interface AWSLocationResponse {
  Categories: string[];
  PlaceId: string;
  Text: string;
  Region?: string;
}

export type TeamProps = {
  id: number;
  teamId: number;
  team: string;
  conference: string;
  division: string;
  league: string;
};

export interface UserAgencyResponse {
  id: number;
  affiliationId: string;
  commissionRate: number;
  businessName: string;
  country: string;
  city: string;
  streetAddress: string;
  agencyOwnerName: string;
  zipCode: string;
  industry: string;
  website: string;
  youtube?: string; // Optional youtube property
  instagram?: string; // Optional instagram property
  twitter?: string; // Optional twitter property
  tiktok?: string; // Optional tiktok property
  additionalInfo: string;
  status: 'accepted'; // Enforces status to be "accepted"
  createdAt: string;
}

export interface UserAgencyDetails {
  businessName: string;
  agencyOwnerName: string;
  id: number;
  commissionRate: number;
  country: string;
  streetAddress: string;
  city: string;
  zipCode: string;
  industry?: string | null; // Optional industry field
  website: string;
  agencyEmail: string;
  agencyPhoneNumber: string;
  youtube?: string; // Optional YouTube URL (can be null or undefined)
  instagram?: string; // Optional Instagram URL
  twitter?: string; // Optional Twitter URL
  tiktok?: string; // Optional TikTok URL
  affiliateLink?: string; // Optional affiliate link
  createdAt: string;
  additionalInfo: string;
  status: string;
  agencyOwner: {
    agencyOwnerName: string;
    email: string;
    dateOfBirth: string;
    phoneNumber: string;
    gender: string;
  };
}

export interface UserAgencySales {
  id: number;
  orderId: number;
  orderValue: string;
  commission: string;
  currency: string;
  status: string;
  orderDate: string;
  agentName: string;
}
[];

export interface AgencyAffiliateCode {
  id: number;
  code: string;
  type: AgencyDiscount;
  amount: string;
  amountType: AgencyAmount;
  currency: string | null;
  minOrderPrice: number | null;
  useLimit: number | null;
  amountUsed: string;
  usedTimes: number;
  status: string;
  startDate: Date | null;
  endDate: Date | null;
  createdByUser: string;
  createdAt: string;
  createdByUserId: number;
}

export interface AgentAffiliateCode {
  id: number;
  code: string;
  type: AgencyDiscount;
  amount: string;
  amountUsed: string;
  status: string;
  startDate: Date | null;
  endDate: Date | null;
}

export interface UserAgencyDetailPerformance {
  affiliationId: string;
  agentName: string;
  totalVisits: number;
  totalUsers: number;
}
[];

export interface AgencyTeam {
  userId: number;
  agencyTeamMemberId: number;
  userName: string;
  email: string;
  affiliateLink: string;
  code: string;
  status: string;
}

export interface AgencyTeamMember {
  results: AgencyTeam[];
  currentPage: 1;
  pageSize: number;
  totalCount: number;
  totalPages: number;
}

export interface AgentInviteResponse {
  inviteId: number;
  email: string;
  lastName: string;
  firstName: string;
  businessName: string;
  userId: number;
  agencyTeamMemberId: number;
}

export interface AgentDetailsResponse {
  agentName: string;
  affiliateLink: string;
  affiliateDiscountCodes: AgentAffiliateCode[];
  email: string;
  phoneNumber: string;
  dateOfBirth?: string;
  emergencyContact?: string[];
  gender?: string;
}

export interface AgencyAdminResponse {
  id: number;
  agentsCount: number;
  commissionRate: number;
  phoneNumber: string | null;
  email: string | null;
  businessName: string;
  country: string;
  stateProvince: string | null;
  city: string;
  streetAddress: string;
  zipCode: string;
  website: string | null;
  youtube: string;
  instagram: string;
  facebook: string | null;
  tiktok: string;
  twitter: string;
  status: 'pending' | 'accepted'; // Assuming there could be other statuses
  agencyOwnerUserId: number;
  sportsTeamToPromote: string;
  marketOrProductInterest: string;
  interestInSelling: string;
  defaultCurrency: string;
  annualBusinessValue: string;
  agencyOwnerName: string;
  createdAt: string;
}

export interface AgencyPerformance {
  totalNrOfVisits: number;
  totalSales: number;
  totalValue: number;
  usersNr: number;
  totalReferrals: number;
  totalRevenue: number;
  amountSaved: number;
  lastUsed: number;
  lastCreated: number;
  codeCount: number;
}
export type AgencyDiscount = 'giftCard' | 'promoCode' | 'affiliateCode';

export type AgencyAmount = 'fixed' | 'percentage';

export const AGENCY_AMOUNT_MAP = {
  fixed: '$',
  percentage: '%',
};

export const AGENCY_DISCOUNT_MAP = {
  giftCard: 'Gift Card',
  promoCode: 'Promo Code',
  affiliateCode: 'Affiliate Code',
};

// PARTNERS

export type PartnerSale = {
  userId: number;
  affiliationId: string;
  username: string;
  role: number;
  ordersNr: number;
  ordersValue: string;
  currency: string;
  totalCommission: string;
};

export type PartnerPerformance = {
  userId: number;
  username: string;
  role: number;
  joinDate: string;
  totalReach: number;
  usersConverted: number;
  conversionRate: string;
  orderConversionRate: string;
};

export type AdminCacheLog = {
  id: string;
  status: string;
  hasError: boolean;
  type: string;
  createdAt: Date;
  updatedAt: Date;
};
